define("gestor-projetos/pods/application/route", ["exports", "gestor-projetos/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ApplicationRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class ApplicationRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "moment", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "socket", _descriptor5, this);
      _initializerDefineProperty(this, "swal", _descriptor6, this);
      _initializerDefineProperty(this, "userCookie", _descriptor7, this);
    }
    model() {
      return this.permission.load.perform();
    }
    async afterModel() {
      if (!this.session.isAuthenticated) {
        return;
      }

      // Remove o backup da session (login do superUser) caso seja do mesmo usuário logado
      if (this.session?.user.id === this.session.data?.keybkp?.tokenData?.user.id) {
        this.session.set('data.keybkp', undefined);
      }
      let accountIsActive = this.permission.companyConfig.accountIsActive;
      if (!accountIsActive) {
        this.transitionTo('errors.inactive-account');
      }
      if (!this.permission.companyConfig.hasProjectManagerModule) {
        return this.swal.fire({
          title: 'Ops!',
          text: 'Você não tem acesso a este módulo. Entre em contato com seu administrador do sistema para mais informações',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false
        }).then(() => {
          let href = '';
          if (this.permission.companyConfig.showPortalColab) {
            href = _environment.default.appUrl.social;
          } else if (this.permission.companyConfig.hasPortalGestorModule) {
            href = _environment.default.appUrl.conta;
          } else if (this.permission.companyConfig.hasAVDModule) {
            href = _environment.default.appUrl.avd;
          } else if (this.permission.companyConfig.hasTEDModule) {
            href = _environment.default.appUrl.ted;
          } else if (this.permission.companyConfig.hasPesquisaModule) {
            href = _environment.default.appUrl.pesquisa;
          } else {
            href = _environment.default.appUrl.agenda;
          }
          document.location.href = href;
        });
      }
      await this.userCookie.handle();
    }
    error(adapterError) {
      if (!adapterError || !adapterError.errors || !adapterError.errors.length || !adapterError.errors[0].status) {
        return;
      }
      let status = adapterError.errors[0].status;
      if (status === '400') {
        if (adapterError.errors[0].detail) {
          return this.swal.error(adapterError.errors[0].detail);
        }
        return this.swal.error('Por favor, tente novamente mais tarde', {
          title: 'Erro inesperado'
        });
      }
      if (status === '403') {
        this.swal.catch(adapterError);
        return this.transitionTo('errors.forbidden');
      }
      if (status === '404') {
        return this.transitionTo('errors.not-found');
      }
      if (status === '500') {
        return this.swal.error('Por favor, tente novamente mais tarde', {
          title: '500 - Erro inesperado'
        });
      }
      return this.transitionTo('errors.not-found');
    }
    willTransition() {
      Ember.set(this, 'controller.searchTerm', '');
    }
    reloadRoute() {
      this.refresh();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "moment", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "socket", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "userCookie", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "error", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadRoute", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "reloadRoute"), _class.prototype)), _class));
  _exports.default = ApplicationRoute;
});
define("gestor-projetos/ui-nela/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/load-records.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/load-records.hbs should pass TemplateLint.\n\naddon/templates/components/load-records.hbs\n  1:8  error  Ambiguous path \'query.last.value\' is not allowed. Use \'@query.last.value\' if it is a named argument or \'this.query.last.value\' if it is a property on \'this\'. If it is a helper or component that has no arguments, you must either convert it to an angle bracket invocation or manually add it to the \'no-implicit-this\' rule configuration, e.g. \'no-implicit-this\': { allow: [\'query.last.value\'] }.  no-implicit-this\n');
  });
  QUnit.test('addon/templates/components/ui-application.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-application.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-avatar.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-avatar.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-block-content.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-block-content.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-block-title.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-block-title.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-block.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-block.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-button-link.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-button-link.hbs should pass TemplateLint.\n\naddon/templates/components/ui-button-link.hbs\n  3:4  error  You are using the component {{link-to}} with curly component syntax. You should use <LinkTo> instead. If it is actually a helper you must manually add it to the \'no-curly-component-invocation\' rule configuration, e.g. `\'no-curly-component-invocation\': { allow: [\'link-to\'] }`.  no-curly-component-invocation\n');
  });
  QUnit.test('addon/templates/components/ui-button.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-button.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-card.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-card.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-card/body.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-card/body.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-card/footer.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-card/footer.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-card/header.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-card/header.hbs should pass TemplateLint.\n\naddon/templates/components/ui-card/header.hbs\n  4:14  error  Do not use `action` as (action ...). Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/ui-card/header/btn-group.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-card/header/btn-group.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-card/header/title.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-card/header/title.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-checkbox.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-checkbox.hbs should pass TemplateLint.\n\naddon/templates/components/ui-checkbox.hbs\n  5:12  error  Do not use `action` as {{action ...}}. Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/ui-checkbox/input.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-checkbox/input.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-container.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-container.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-crud.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-crud.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-crud/list.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-crud/list.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-crud/list/header.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-crud/list/header.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-crud/list/header/tabs.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-crud/list/header/tabs.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-crud/list/header/tabs/tab-btn.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-crud/list/header/tabs/tab-btn.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-crud/list/list-group.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-crud/list/list-group.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-crud/view.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-crud/view.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-crud/view/section.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-crud/view/section.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-crud/view/section/tabs.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-crud/view/section/tabs.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-crud/view/section/tabs/link.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-crud/view/section/tabs/link.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-form-renderer.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-form-renderer.hbs should pass TemplateLint.\n\naddon/templates/components/ui-form-renderer.hbs\n  3:15  error  Do not use `action` as (action ...). Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/ui-form.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-form.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-form/checkbox.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-form/checkbox.hbs should pass TemplateLint.\n\naddon/templates/components/ui-form/checkbox.hbs\n  8:12  error  Do not use `action` as {{action ...}}. Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/ui-form/group.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-form/group.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-form/group/label.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-form/group/label.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-form/group/status.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-form/group/status.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-form/input.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-form/input.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-form/label.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-form/label.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-form/radio-group.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-form/radio-group.hbs should pass TemplateLint.\n\naddon/templates/components/ui-form/radio-group.hbs\n  5:18  error  Do not use `action` as (action ...). Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/ui-form/radio-group/radio-button.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-form/radio-group/radio-button.hbs should pass TemplateLint.\n\naddon/templates/components/ui-form/radio-group/radio-button.hbs\n  5:9  error  Do not use `action` as {{action ...}}. Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/ui-h6.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-h6.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-list.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-list.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-list/action-item.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-list/action-item.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-list/list-item.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-list/list-item.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-login.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-login.hbs should pass TemplateLint.\n\naddon/templates/components/ui-login.hbs\n  73:8  error  links with target="_blank" must have rel="noopener noreferrer" or rel="noreferrer noopener"  link-rel-noopener\n  70:69  error  Interaction added to non-interactive element  no-invalid-interactive\n  60:6  error  Invalid alt attribute. Words such as `image`, `photo,` or `picture` are already announced by screen readers.  require-valid-alt-text\n  88:6  error  Invalid alt attribute. Words such as `image`, `photo,` or `picture` are already announced by screen readers.  require-valid-alt-text\n  22:25  error  Do not use `action` as (action ...). Instead, use the `on` modifier and `fn` helper.  no-action\n  70:82  error  Do not use `action` as (action ...). Instead, use the `on` modifier and `fn` helper.  no-action\n  95:55  error  Do not use `action` as {{action ...}}. Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/ui-logo.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-logo.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-modal.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-modal.hbs should pass TemplateLint.\n\naddon/templates/components/ui-modal.hbs\n  4:51  error  Do not use `action` as (action ...). Instead, use the `on` modifier and `fn` helper.  no-action\n  6:53  error  Do not use `action` as (action ...). Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/ui-modal/body.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-modal/body.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-modal/footer.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-modal/footer.hbs should pass TemplateLint.\n\naddon/templates/components/ui-modal/footer.hbs\n  3:69  error  Do not use `action` as (action ...). Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/ui-modal/title.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-modal/title.hbs should pass TemplateLint.\n\naddon/templates/components/ui-modal/title.hbs\n  3:44  error  Do not use `action` as {{action ...}}. Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/ui-navbar.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-navbar.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-navbar/brand.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-navbar/brand.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-navbar/logo.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-navbar/logo.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-navbar/nav.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-navbar/nav.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-navbar/nav/dropdown.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-navbar/nav/dropdown.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-navbar/nav/dropdown/item.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-navbar/nav/dropdown/item.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-navbar/nav/item.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-navbar/nav/item.hbs should pass TemplateLint.\n\naddon/templates/components/ui-navbar/nav/item.hbs\n  1:10  error  Ambiguous path \'usesContextualButton\' is not allowed. Use \'@usesContextualButton\' if it is a named argument or \'this.usesContextualButton\' if it is a property on \'this\'. If it is a helper or component that has no arguments, you must either convert it to an angle bracket invocation or manually add it to the \'no-implicit-this\' rule configuration, e.g. \'no-implicit-this\': { allow: [\'usesContextualButton\'] }.  no-implicit-this\n  49:69  error  Ambiguous path \'usesContextualButton\' is not allowed. Use \'@usesContextualButton\' if it is a named argument or \'this.usesContextualButton\' if it is a property on \'this\'. If it is a helper or component that has no arguments, you must either convert it to an angle bracket invocation or manually add it to the \'no-implicit-this\' rule configuration, e.g. \'no-implicit-this\': { allow: [\'usesContextualButton\'] }.  no-implicit-this\n');
  });
  QUnit.test('addon/templates/components/ui-navbar/nav/item/badge.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-navbar/nav/item/badge.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-navbar/nav/item/button.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-navbar/nav/item/button.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-navbar/nav/modules.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-navbar/nav/modules.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-navbar/nav/search.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-navbar/nav/search.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-navbar/nav/user.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-navbar/nav/user.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-page.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-page.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-page/header.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-page/header.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-page/header/breadcrumbs.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-page/header/breadcrumbs.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-page/header/breadcrumbs/crumb.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-page/header/breadcrumbs/crumb.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-page/header/title.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-page/header/title.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-page/section.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-page/section.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-radio.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-radio.hbs should pass TemplateLint.\n\naddon/templates/components/ui-radio.hbs\n  6:14  error  Do not use `action` as {{action ...}}. Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/ui-radio/input.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-radio/input.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-sidebar.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-sidebar.hbs should pass TemplateLint.\n\naddon/templates/components/ui-sidebar.hbs\n  4:58  error  Do not use `action` as <span {{action ...}} />. Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/ui-sidebar/logo.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-sidebar/logo.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-sidebar/menu-item.hbs', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/templates/components/ui-sidebar/menu-item.hbs should pass TemplateLint.\n\naddon/templates/components/ui-sidebar/menu-item.hbs\n  15:64  error  Do not use `action` as (action ...). Instead, use the `on` modifier and `fn` helper.  no-action\n');
  });
  QUnit.test('addon/templates/components/ui-sidebar/menu-item/submenu.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-sidebar/menu-item/submenu.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-sidebar/menu-item/submenu/submenu-item.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-sidebar/menu-item/submenu/submenu-item.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-skeleton.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-skeleton.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-skeleton/avatar.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-skeleton/avatar.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-skeleton/render.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-skeleton/render.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-skeleton/text.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-skeleton/text.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-skeleton/text/line.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-skeleton/text/line.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-tabs.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-tabs.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-textarea.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-textarea.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/ui-title.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/ui-title.hbs should pass TemplateLint.\n\n');
  });
});